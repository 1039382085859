import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout locationState={null}>
    <SEO title="404: страница не найдена" description="Запрашиваемая страница не найдена." />
    <h1>Страница не найдена</h1>
  </Layout>
)

export default NotFoundPage
